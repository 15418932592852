export const FAQDATA = [
  {
    question: "What services do you offer?",
    answer:
      "We offer a comprehensive range of IT services, including web development, mobile app development, cloud solutions, database management, and IT consulting. Our goal is to provide tailored solutions to help your business grow and succeed in the digital landscape.",
  },
  {
    question: "How do you ensure the security of my data?",
    answer:
      "Data security is our top priority. We implement robust security measures, including encryption, regular security audits, secure coding practices, and compliance with industry standards such as GDPR and HIPAA. Our team continuously monitors and updates security protocols to protect your data from threats.",
  },
  {
    question: "What is the typical timeline for a development project?",
    answer:
      "The timeline for a development project varies depending on the project's scope and complexity. On average, a small to medium-sized project can take between 2 to 4 months. During the initial consultation, we provide a detailed timeline and keep you informed of progress throughout the development process.",
  },
  {
    question: "How do you handle project management and communication?",
    answer:
      "We use agile project management methodologies to ensure efficient and transparent communication. Our team utilizes tools like Jira, Trello, and Slack to manage tasks, track progress, and facilitate real-time collaboration. You will have a dedicated project manager who will be your main point of contact throughout the project.",
  },
  {
    question: "Can you help with post-launch support and maintenance?",
    answer:
      "Absolutely! We offer comprehensive post-launch support and maintenance services to ensure your system runs smoothly. Our services include bug fixing, updates, performance optimization, and ongoing technical support to address any issues that may arise and keep your systems up-to-date with the latest technologies.",
  },
  {
    question: "What sets you apart from other IT service providers?",
    answer:
      "Our team combines deep industry knowledge with cutting-edge IT skills to deliver innovative solutions that empower your business for growth. We prioritize speed, adaptability, security, user experience, operational efficiency, scalability, and cost-effectiveness to ensure your success in the digital landscape.",
  },
  {
    question: "How do I get started with your services?",
    answer:
      "Getting started with our services is easy! Simply reach out to us via phone, email, or our website contact form to schedule an initial consultation. During the consultation, we will discuss your project requirements, goals, and timeline to create a customized solution that meets your needs.",
  },
];

export const WhyUsData = [
  {
    category: "Foundation",
    type: "Technical Expertise",
    description:
      "We combine deep industry knowledge with cutting-edge IT skills to craft innovative solutions for your complex challenges. Get future-proof technology to empower your business for growth.",
  },
  {
    category: "Speed & Adaptability",
    type: "Agile Development",
    description:
      "Embrace change and get to market faster with our agile approach. This method adapts to your needs, fosters collaboration, and streamlines development.",
  },
  {
    category: "Confidence & Security",
    type: "Testing",
    description:
      "Our rigorous testing procedures ensure your solution is thoroughly vetted for functionality, usability, and security. We deliver robust, secure solutions that exceed expectations, giving you peace of mind.",
  },
  {
    category: "User Experience",
    type: "User Interfaces & Experience",
    description:
      "We believe in beautiful interfaces that are also intuitive. Our user experience design creates seamless, engaging experiences that drive user satisfaction and business success.",
  },
  {
    category: "Operational Efficiency",
    type: "Support",
    description:
      "Proactive IT support keeps your systems running smoothly. We offer 24/7 monitoring, timely updates, and rapid troubleshooting to minimize downtime, ensuring efficient operations.",
  },
  {
    category: "Scalability & Growth",
    type: "Scalability & Reliability",
    description:
      "We design IT solutions to grow with your business. Our scalable and reliable systems seamlessly adapt to your evolving needs, ensuring high uptime and a foundation for future success.",
  },
  {
    category: "Cost-Effectiveness",
    type: "Lower Cost",
    description:
      "Get advanced IT solutions without compromising on quality or performance. We optimize processes to deliver high-value solutions at competitive prices, making advanced technology accessible.",
  },
];
