import IconLogo from "../Assets/Images/favicon.png";
import Text from "../Components/Core/Text";
import { AiFillLinkedin, AiOutlineTwitter } from "react-icons/ai";
import { Box, Tooltip } from "@mui/material";
import Button from "../Components/Core/Button";
import { IoArrowForwardOutline } from "react-icons/io5";

interface IFooter {
  onClickFreeConsultation: () => void;
  onClickExploreServices: () => void;
  onServicesClick: () => void;
  onWhyUsClick: () => void;
  onFaqClick: () => void;
  onAboutClick: () => void;
}
const Footer = (props: IFooter) => {
  const menuItems = [
    {
      name: "Services",
      link: props.onServicesClick,
    },
    {
      name: "Why Us?",
      link: props.onWhyUsClick,
    },
    {
      name: "Contact",
      link: props.onFaqClick,
    },
    {
      name: "About",
      link: props.onAboutClick,
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "black",
        width: "100%",
        padding: "1em 0",
      }}
    >
      <Box className="footerHead">
        <Box>
          <Box
            sx={{
              width: "fit-content",
            }}
            className="header-button  animate__animated animate__fadeInUp"
          >
            <div />
            <Tooltip title="Book a 90 min free consultation with us">
              <span>Take Action Today</span>
            </Tooltip>
          </Box>
          <Text type="h1" className="footerHeadTitle">
            Ready to supercharge your business? Let's connect
          </Text>
        </Box>
        <Box className="footerHead_Btns">
          <Button
            style={{
              backgroundColor: "white",
              color: "#002741",
              fontSize: 15,
              height: 50,
              padding: "0 20px",
              marginTop: "2em",
            }}
            onClick={props.onClickExploreServices}
          >
            Explore Services
            <IoArrowForwardOutline
              size={20}
              style={{
                marginLeft: "8px",
              }}
            />
          </Button>

          <Button
            style={{
              fontSize: 15,
              height: 50,
              padding: "0 20px",
              marginTop: "2em",
            }}
            color="secondary"
            onClick={props.onClickFreeConsultation}
          >
            Book Free Consulation
          </Button>
        </Box>
      </Box>
      <Box className="footerBody">
        <Box className="footerBodyFlex">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src={IconLogo}
              alt="logo"
              style={{
                width: "100px",
                height: "100px",
              }}
            />
            <Text
              style={{
                color: "white",
                fontSize: 32,
                opacity: 0.8,
                marginLeft: "-8px",
              }}
            >
              Insyt
            </Text>
          </Box>
          <Text className="footerBusinessHrsTitle">
            Your Trusted Partner in IT Consultation & Development Services.
            Transforming Ideas into Digital Excellence.
          </Text>
          <Text
            style={{
              marginTop: ".8em",
              fontWeight: "bold !important",
              opacity: 0.8,
            }}
            className="footerBusinessHrsTitle"
          >
            contact@insyt.in
          </Text>
          <Box>
            <a
              href="https://www.linkedin.com/company/insyt-in/"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillLinkedin
                size="30px"
                color="#fff"
                className="footerSocialMedia"
              />
            </a>
            <a
              href="https://x.com/InsytHQ"
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineTwitter
                size="30px"
                color="#fff"
                className="footerSocialMedia"
              />
            </a>
          </Box>
        </Box>
        <Box className="footerBodyFlex">
          <Text className="footerTitle">Resources & Support</Text>
          {menuItems.map((item, index) => (
            <span
              key={index}
              className="footerBusinessHrsTitle"
              style={{
                paddingBottom: "12px",
                width: "fit-content",
                cursor: "pointer",
              }}
              onClick={item.link}
            >
              {item.name}
            </span>
          ))}
        </Box>
        <Box className="footerBodyFlex">
          <Text className="footerTitle">
            Business Hours{" "}
            <span
              style={{
                fontSize: "12px",
                color: "white",
                opacity: 0.5,
              }}
            >
              (EST)
            </span>
          </Text>
          <Box className="footerBusinessHrs">
            <Text className="footerBusinessHrsTitle">Weekdays</Text>
            <Text className="footerBusinessHrsTime">10:00AM - 5:00PM</Text>
          </Box>
          <Box className="footerBusinessHrs">
            <Text className="footerBusinessHrsTitle">Saturday</Text>
            <Text className="footerBusinessHrsTime">9:00AM - 5:00PM</Text>
          </Box>
          <Box className="footerBusinessHrs">
            <Text className="footerBusinessHrsTitle">Sunday</Text>
            <Text className="footerBusinessHrsTime">Closed</Text>
          </Box>
        </Box>
      </Box>
      <Box className="footerFoot">
        <span>© 2023 All rights reserved</span>
        <Box className="footerLinks">
          <p onClick={() => window.open("/privacy-policy")}>Privacy Policy</p>
          <p onClick={() => window.open("/terms-conditions")}>
            Terms & Conditions
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
