import { styled } from "@mui/material/styles";
import ButtonBox from "@mui/material/Button";
import { IButtonProps } from "../../Types/Components";
import { CircularProgress, Tooltip } from "@mui/material";

export default function Button(props: IButtonProps) {
  interface IColor {
    background: string;
    borderColor: string;
  }
  const getColors = () => {
    var bgColor: IColor = {
      background: "",
      borderColor: "",
    };

    switch (props.color) {
      case "primary":
        bgColor = {
          background: "#30313d",
          borderColor: "#28282f",
        };
        break;
      case "secondary":
        bgColor = {
          background: "#2dd06b",
          borderColor: "#35ab62",
        };
        break;
      case "secondaryFilled":
        bgColor = {
          background: "#2dd06b65",
          borderColor: "#2dd06b",
        };
        break;
      case "error":
        bgColor = {
          background: "#fd4757",
          borderColor: "#a90916",
        };
        break;
      case "normal":
        bgColor = {
          background: "#f3f5f9",
          borderColor: "transparent",
        };
        break;
      case "transparent":
        bgColor = {
          background: "transparent",
          borderColor: "lightgray",
        };
        break;
    }
    return bgColor;
  };

  const getRadius = () => {
    switch (props.shape) {
      case "rounded":
        return 24;
      case "corner":
        return 8;
      case "normal":
        return 2;
      case "circle":
        return 100;
      default:
        return 2;
    }
  };

  const getPadding = () => {
    switch (props.padding) {
      case "xy":
        return "4px";
      case "xxy":
        return "8px 4px";
      case "xyy":
        return "4px 8px";
      case "wide":
        return "6px 12px";
      case "widden":
        return "8px 14px";
      case "normal":
        return "6px 10px";
      default:
        return "6px 10px";
    }
  };

  const BootstrapButton = styled(ButtonBox)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: props.fontSize ? props.fontSize : 18,
    padding: props.padding
      ? getPadding()
      : props.style?.padding
      ? props.style.padding
      : "6px 10px",
    border: "1px solid",
    display: "flex",
    flexDirection: "row",
    minWidth: props.style?.minWidth ? props.style.minWidth : "auto",
    alignItems: props.align ? props.align : "center",
    margin: props.margin ? props.margin : "2px",
    color: props.fontColor ? props.fontColor : "#fff",
    borderRadius: getRadius(),
    lineHeight: 1.5,
    backgroundColor: getColors().background,
    borderColor: props.style?.backgroundColor || getColors().borderColor,
    fontFamily: ["Arimo, sans-serif"].join(","),
    "&:hover": {
      opacity: 0.9,
      boxShadow: "none",
      backgroundColor: props.style?.backgroundColor || getColors().background,
      transition: "all 0.2s ease-in-out",
    },
    "&:active": {
      opacity: 0.9,
      boxShadow: "none",
      backgroundColor: props.style?.backgroundColor || getColors().background,
      transition: "all 0.2s ease-in-out",
    },
    "&:focus": {
      boxShadow: `0 0 0 0.2rem rgba(${getColors().background}, 0.5)`,
    },
  });

  return (
    <Tooltip title={props.title ? props.title : ""}>
      <BootstrapButton
        sx={{ ...props.style }}
        size={props.size}
        variant="contained"
        disableRipple
        onClick={props.onClick}
        href={props.href}
        disabled={props.disabled}
        className={props.className}
      >
        {props.isLoading ? (
          <CircularProgress sx={{ color: props.fontColor }} size={20} />
        ) : (
          props.children
        )}
      </BootstrapButton>
    </Tooltip>
  );
}
