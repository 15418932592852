import { Box, Tooltip } from "@mui/material";
import IconLogo from "../Assets/Images/favicon.png";
import { AiFillLinkedin, AiOutlineTwitter } from "react-icons/ai";
import "./index.css";
import { IoIosMenu } from "react-icons/io";
import { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { LiaAngleRightSolid } from "react-icons/lia";

interface IHeader {
  onHomeClick: () => void;
  onClickFreeConsultation: () => void;
  onServicesClick: () => void;
  onWhyUsClick: () => void;
  onFaqClick: () => void;
  onAboutClick: () => void;
}
const Header = (props: IHeader) => {
  const [showMenuItems, setshowMenuItems] = useState(false);
  const headerRef = useRef<any>(null);
  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const menuItems = [
    {
      name: "Home",
      link: props.onHomeClick,
    },
    {
      name: "Why Us?",
      link: props.onWhyUsClick,
    },
    {
      name: "Services",
      link: props.onServicesClick,
    },
    {
      name: "Contact",
      link: props.onFaqClick,
    },
    {
      name: "About",
      link: props.onAboutClick,
    },
  ];

  const handleClickOutside = (e: any) => {
    const { current: wrap } = headerRef;
    if (wrap && !wrap?.contains(e.target)) {
      setshowMenuItems(false);
    }
  };

  return (
    <>
      <Box className="header">
        <img
          src={IconLogo}
          alt="logo"
          style={{
            width: "100px",
            height: "100px",
            backgroundColor: "transparent",
          }}
        />
        <Box
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.508)",
            backdropFilter: "blur(20px)",
            padding: "4.4px",
            borderRadius: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {menuItems.map((item, index) => (
            <span
              key={index}
              onClick={item.link}
              style={{
                color: "white",
                margin: "0 10px",
                fontSize: 14,
                opacity: 0.8,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              className="header-menu-item"
            >
              {item.name}
            </span>
          ))}
          <button
            onClick={props.onClickFreeConsultation}
            className="header-button"
          >
            <div />
            <Tooltip title="Book a 90 min free consultation with us">
              <span>Free Consultation</span>
            </Tooltip>
          </button>
        </Box>
        <Box
          sx={{
            backgroundColor: "transparent",
            padding: "10px 20px",
          }}
        >
          <a
            href="https://www.linkedin.com/company/insyt-in/"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "white",
              backgroundColor: "transparent",
              marginRight: "10px",
              opacity: 0.8,
            }}
          >
            <AiFillLinkedin
              size="30px"
              color="#fff"
              style={{ backgroundColor: "transparent" }}
            />
          </a>
          <a
            href="https://x.com/InsytHQ"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "white",
              backgroundColor: "transparent",
              marginRight: "16px",
              opacity: 0.8,
            }}
          >
            <AiOutlineTwitter
              size="30px"
              color="#fff"
              style={{ backgroundColor: "transparent" }}
            />
          </a>
        </Box>
      </Box>
      <Box className="header-mobile">
        <img
          src={IconLogo}
          alt="logo"
          style={{
            width: "100px",
            height: "100px",
            backgroundColor: "transparent",
          }}
        />
        <IoIosMenu
          onClick={() => setshowMenuItems(!showMenuItems)}
          size={30}
          color="#fff"
          style={{
            backgroundColor: "transparent",
            marginLeft: "auto",
            marginTop: "auto",
            marginBottom: "auto",
            cursor: "pointer",
          }}
        />
      </Box>
      {showMenuItems && (
        <Box
          ref={headerRef}
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.314)",
            backdropFilter: "blur(20px)",
            borderTopRightRadius: "16px",
            borderBottomRightRadius: "16px",
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            height: "100vh",
            width: "68%",
            zIndex: 1000,
          }}
          className="animate__animated animate__fadeInLeftBig"
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <img
              src={IconLogo}
              alt="logo"
              style={{
                width: "80px",
                height: "80px",
                backgroundColor: "transparent",
              }}
            />
            <IoClose
              onClick={() => setshowMenuItems(!showMenuItems)}
              size={30}
              color="#fff"
              style={{
                backgroundColor: "transparent",
                marginLeft: "auto",
                cursor: "pointer",
                marginTop: "auto",
                marginBottom: "auto",
                opacity: 0.7,
                marginRight: "10px",
              }}
            />
          </Box>
          {menuItems.map((item, index) => (
            <Box
              onClick={() => {
                item.link();
                setshowMenuItems(false);
              }}
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                margin: "10px",
                cursor: "pointer",
                opacity: 0.8,
                padding: "0 4px",
              }}
            >
              <span
                style={{
                  color: "white",
                  fontSize: 14,
                  backgroundColor: "transparent",
                  marginRight: "auto",
                }}
              >
                {item.name}
              </span>
              <LiaAngleRightSolid />
            </Box>
          ))}
          <button
            onClick={() => {
              props.onClickFreeConsultation();
              setshowMenuItems(false);
            }}
            className="header-button"
            style={{
              height: "50px",
              width: "95%",
              margin: "1.6em auto",
            }}
          >
            <div />
            <Tooltip title="Book a 90 min free consultation with us">
              <span>Free Consultation</span>
            </Tooltip>
          </button>

          <Box
            sx={{
              padding: "0 16px",
            }}
          >
            <a
              href="https://www.linkedin.com/company/insyt-in/"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
                backgroundColor: "transparent",
                marginRight: "10px",
                opacity: 0.8,
              }}
            >
              <AiFillLinkedin
                size="30px"
                color="#fff"
                style={{ backgroundColor: "transparent" }}
              />
            </a>
            <a
              href="https://x.com/InsytHQ"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
                backgroundColor: "transparent",
                marginRight: "16px",
                opacity: 0.8,
              }}
            >
              <AiOutlineTwitter
                size="30px"
                color="#fff"
                style={{ backgroundColor: "transparent" }}
              />
            </a>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Header;
