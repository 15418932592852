import "./App.css";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Route, Routes } from "react-router";
import Landing from "./Pages/Landing";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TNC from "./Pages/TNC";
import Error from "./Error";

function App() {
  
  useEffect(() => {
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  }, []);

  return (
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TNC />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
