import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { IoIosArrowDown } from "react-icons/io";
import "./index.css";

interface FAQCardProps {
  question: string;
  answer: string;
}

const FAQCard = (props: FAQCardProps) => {
  return (
    <Accordion className="faqCard">
      <AccordionSummary
        expandIcon={
          <IoIosArrowDown
            color="white"
            size={20}
            style={{
              opacity: 0.6,
            }}
          />
        }
        aria-controls="panel1-content"
        id="panel1-header"
        className="faqQuestion"
      >
        {props.question}
      </AccordionSummary>
      <AccordionDetails
        style={{
          color: "white",
          fontWeight: "normal",
          fontSize: "13.6px",
          lineHeight: "1.8",
          opacity: 0.7,
        }}
      >
        {props.answer}
      </AccordionDetails>
    </Accordion>
  );
};

export default FAQCard;
