import { Box } from "@mui/material";
import "./index.css";
import Text from "./Core/Text";

interface WhyUsCardProps {
  category: string;
  title: string;
  description: string;
  icon?: string;
  index: number;
}

const WhyUsCard = (props: WhyUsCardProps) => {
  return (
    <Box className="why-us-card">
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box className="why-us-icon">
          <Text>{props.index + 1}</Text>
        </Box>
        {props.index !== 6 && <Box className="why-us-timeline" />}
      </Box>
      <Box
        sx={{ml: 2}}
      >
        <Text type="h3" className="why-us-card-title">
          {props.category}
        </Text>
        <Text className="why-us-card-description">{props.description}</Text>
      </Box>
    </Box>
  );
};

export default WhyUsCard;
