import { Box } from '@mui/material'
import errorImg from './Assets/Images/error.svg';
import "./App.css"

const Error = () => {
  return (
    <Box className="errorPage">
      <img src={errorImg} alt="error" />
    </Box>
  )
}

export default Error