import { Typography } from "@mui/material";
import { ITextProps } from "../../Types/Components";

const Text = (props: ITextProps) => {
  const getVariant = () => {
    switch (props.type) {
      case "h1":
        return "h1";
      case "h2":
        return "h2";
      case "h3":
        return "h3";
      case "h4":
        return "h4";
      case "h5":
        return "h5";
      case "h6":
        return "h6";
      case "p":
        return "body1";
      case "span":
        return "body2";
      case "body":
        return "body1";
      case "subtitle":
        return "subtitle1";
      default:
        return "body1";
    }
  };

  const getFontSize = () => {
    switch (props.type) {
      case "h1":
        return 56;
      case "h2":
        return 42;
      case "h3":
        return 36;
      case "h4":
        return 32;
      case "h5":
        return 28;
      case "h6":
        return 24;
      case "p":
        return 18;
      case "span":
        return 14;
      case "body":
        return 16;
      case "subtitle":
        return 14;
      default:
        return 18;
    }
  };

  const getFontColors = () => {
    switch (props.color) {
      case "primary":
        return "#30313d";
      case "secondary":
        return "#2dd06b";
      case "error":
        return "#fd4757";
      case "success":
        return "#2dd06b";
      case "info":
        return "#00aef2";
      case "warning":
        return "#f6cd44";
      case "white":
        return "#fff";
      case "normal":
        return "#333";
      default:
        return "#333";
    }
  };

  return (
    <Typography
      fontWeight={props.fontWeight ? props.fontWeight : "normal"}
      fontFamily={
        props.fontFamily === "normal" ? props.fontFamily : "Arimo, sans-serif"
      }
      fontStyle={props.fontStyle ? props.fontStyle : "normal"}
      align={props.align}
      lineHeight={props.lineHeight ? props.lineHeight : 1.2}
      width={props.width ? props.width : "auto"}
      sx={[{ ...props.style }, { userSelect: "none", pointerEvents: "none" }]}
      fontSize={props.fontSize ? props.fontSize : getFontSize()}
      variant={getVariant()}
      color={getFontColors()}
      className={props.className}
      onClick={props.href ? () => window.open(props.href, "_self") : () => {}}
    >
      {props.children}
    </Typography>
  );
};

export default Text;
