import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { ITextInputProps } from "../../Types/Components";

const BootstrapInput = styled(InputBase)(({ theme, multiline }) => ({
  "label + &": {
    marginTop: theme.spacing(2.7),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "white" : "#1A2027",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    minHeight: multiline ? "160px" : "auto",
    height: multiline ? "160px" : "auto",
    padding: "12px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: ["Arimo, sans-serif"].join(","),
    "&:focus": {
      boxShadow: `${alpha("#2dd06b", 0.25)} 0 0 0 0.2rem`,
      borderColor: "#1b7e5f",
    },
  },
}));

const TextInput = (props: ITextInputProps) => {
  return (
    <FormControl variant="standard" style={props.style}>
      {props.label && (
        <InputLabel shrink htmlFor="bootstrap-input">
          {props.label}
        </InputLabel>
      )}

      <BootstrapInput
        value={props.value}
        onChange={props.onChange}
        type={props.type}
        placeholder={props.placeholder}
        required={props.required}
        disabled={props.disabled}
        error={props.error}
        aria-errormessage="error"
        multiline={props.multiline}
        rows={props.rows}
        maxRows={props.maxRows}
        inputProps={{
          maxLength: props.maxLength,
          minLength: props.minLength,
          readOnly: props.readonly,
        }}
        id="bootstrap-input"
      />
      {props.error && (
        <span style={{ fontSize: 12, color: "orangered", marginTop: "4px" }}>
          {props.errorMessage}
        </span>
      )}
    </FormControl>
  );
};

export default TextInput;
