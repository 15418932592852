import "./index.css";
import { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import Button from "../../Components/Core/Button";
import Text from "../../Components/Core/Text";
import Alert from "../../Components/Core/Alert";
import { Box } from "@mui/material";
import axios from "axios";
import Header from "../../Layout/Header";
import TextInput from "../../Components/Core/TextInput";
import Footer from "../../Layout/Footer";
import FAQCard from "../../Components/FAQCard";
import { FAQDATA, WhyUsData } from "../../Utils/dummyData";
import WhyUsCard from "../../Components/WhyUsCard";
import blob from "../../Assets/Images/blob.svg";
import blob2 from "../../Assets/Images/blob2.svg";
import blob3 from "../../Assets/Images/blob4.svg";
import blob4 from "../../Assets/Images/blob3.svg";
import blob5 from "../../Assets/Images/blob6.svg";
import india from "../../Assets/Icons/india.png";
// import usa from "../../Assets/Icons/usa.png";
import successStories from "../../Assets/Images/successStories.jpg";
import { IoArrowForwardOutline, IoShieldCheckmarkSharp } from "react-icons/io5";
import { MdOutlineCallMade } from "react-icons/md";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../Utils/db";
import moment from "moment";

const Index = () => {
  const [isAlert, setisAlert] = useState<any>({
    type: "",
    message: "",
  });
  const [data, setdata] = useState<any>({
    email: "",
    name: "",
    company: "",
    time: Date.now(),
    message: "",
  });
  const [isLoading, setisLoading] = useState(false);
  const [currentTag, setcurrentTag] = useState("Web Development");
  const [infos, setinfos] = useState({} as any);

  const tags = [
    "Web Development",
    "App Development",
    "Database Management",
    "Emerging Technologies",
  ];

  useEffect(() => {
    const findMyIp = async () => {
      const res = await axios.get("http://ip-api.com/json/");
      return res.data;
    };

    findMyIp()
      .then((res) => {
        setinfos(res);
      })
      .catch((err) => {
        console.log("Error getting account info: ");
      });
  }, []);

  useEffect(() => {
    let index = 0;

    const interval = setInterval(() => {
      setcurrentTag(tags[index]);
      index = (index + 1) % tags.length;
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleSchedule = async () => {
    try {
      if (
        data.email === "" ||
        data.name === "" ||
        data.company === "" ||
        data.time === "" ||
        data.message === ""
      ) {
        setisAlert({
          type: "error",
          message: "Please fill all fields",
        });
        return;
      } else {
        setisLoading(true);
        const docRef = await addDoc(collection(db, "scheduledCalls"), {
          ...data,
          createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          isActive: true,
          review: "",
          mode: "",
          infos: infos,
          services: "",
        });
        if (docRef) {
          setisLoading(false);
          setdata({
            email: "",
            name: "",
            company: "",
            time: "",
            message: "",
          } as any);
          setisAlert({
            type: "success",
            message:
              "Your call has been scheduled. We will contact you soon. Thank you!",
          });
        } else {
          setisLoading(false);
          setisAlert({
            type: "error",
            message: "Error scheduling call",
          });
        }
      }
    } catch (error) {
      setisLoading(false);
      setisAlert({
        type: "error",
        message: "Error scheduling call",
      });
    }
  };

  const scrollToClass = (className: string) => {
    const element = document.getElementsByClassName(className)[0];
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box className="landing">
      <Header
        onHomeClick={() => scrollToClass("col1")}
        onClickFreeConsultation={() => scrollToClass("col1BodyDetails")}
        onServicesClick={() => scrollToClass("landingServices")}
        onWhyUsClick={() => scrollToClass("landingWhy")}
        onFaqClick={() => scrollToClass("landingFaq")}
        onAboutClick={() => scrollToClass("knowMore")}
      />
      <Box className="col1">
        <Box className="col1Body" />
      </Box>
      <Box className="col1BodyDetails">
        <Box
          sx={{ color: "#f2ff58" }}
          className="tag animate__animated animate__fadeInUp"
        >
          {currentTag}
        </Box>
        <Text type="h1" className="headerMainTitle">
          Transforming Ideas into Digital Excellence
        </Text>
        <Text
          type="p"
          style={{
            fontSize: 14.5,
            color: "#fff",
            fontWeight: "normal",
            marginTop: "1em",
            opacity: 0.7,
            lineHeight: 1.6,
          }}
        >
          We offer IT consulting and development that really understands your
          business needs. Our approach helps you use technology more
          effectively, streamline your operations, and stay ahead in a
          fast-moving digital world. Let’s work together to make your IT work
          easier for you.
        </Text>

        <Button
          color="secondary"
          style={{
            marginTop: "2em",
            height: "56px",
            backgroundColor: "white",
            color: "#002741",
            fontWeight: "bold",
            borderRadius: 24,
          }}
          onClick={() => scrollToClass("landingServices")}
        >
          Explore Services
        </Button>
      </Box>
      <Box className="col1BodyConsultaion">
        <Text
          type="h1"
          style={{ fontSize: 36, color: "#002741", fontWeight: "bold" }}
        >
          Book Free Consultation
        </Text>
        <Text
          type="p"
          style={{
            fontSize: 14.5,
            color: "#002741",
            fontWeight: "normal",
            marginTop: "1em",
            opacity: 0.7,
          }}
        >
          Fill out the form below, and we'll arrange a 90 mins free consultation at a time
          most suitable for you. (EST Timezone)
        </Text>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "1em 0",
          }}
        >
          <TextInput
            value={data.name}
            onChange={(e) => setdata({ ...data, name: e.target.value })}
            type="text"
            placeholder="Enter Your Name *"
            style={{ width: "48%", marginRight: "auto" }}
          />
          <TextInput
            value={data.email}
            onChange={(e) => setdata({ ...data, email: e.target.value })}
            type="text"
            placeholder="Enter Your Email *"
            style={{ width: "48%", marginLeft: "auto" }}
          />
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <TextInput
            value={data.time}
            onChange={(e) => setdata({ ...data, time: e.target.value })}
            type="datetime-local"
            placeholder="Enter Your Time *"
            style={{ width: "48%", marginRight: "auto" }}
          />
          <TextInput
            value={data.company}
            onChange={(e) => setdata({ ...data, company: e.target.value })}
            type="text"
            placeholder="Enter Your Company *"
            style={{ width: "48%", marginLeft: "auto" }}
          />
        </Box>
        <TextInput
          value={data.message}
          onChange={(e) => setdata({ ...data, message: e.target.value })}
          type="text"
          placeholder="Enter Your Message *"
          style={{ width: "100%", marginTop: "1em" }}
          multiline
        />

        <Button
          style={{
            marginTop: "1em",
            height: "56px",
          }}
          color="secondary"
          onClick={handleSchedule}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Submit Request
        </Button>
      </Box>
      <Box className="landingWhy animate__animated animate__fadeInUpBig">
        <Text className="landingHeading">Why Us?</Text>
        <Text type="p" className="landingTagline">
          Choose us for personalized IT solutions crafted around your goals. Our
          skilled team blends cutting-edge tech with practical results, offering
          ongoing support to ensure your confidence and success in today's
          digital landscape.
        </Text>
        <Box className="whyUs">
          {WhyUsData.map((item: any, index: any) => (
            <WhyUsCard
              key={index}
              index={index}
              title={item.type}
              description={item.description}
              category={item.category}
            />
          ))}
        </Box>
      </Box>

      <Box className="landingSuccess animate__animated animate__fadeInUpBig">
        <Box className="landingSuccess_head">
          <Box className="landingSuccess_head_left">
            <img src={successStories} alt="successStories" loading="lazy" />
          </Box>
          <Box className="landingSuccess_head_right">
            <Box className="tag" sx={{ color: "#f2ff58" }}>
              Client Stories
            </Box>
            <Text className="landingHeading">
              Hear success stories from our satisfied users
            </Text>
            <Box sx={{ mt: "auto" }}>
              <Text
                type="p"
                style={{ textAlign: "left" }}
                className="landingTagline"
              >
                Hear from our clients about their experience with us and how we
                helped them achieve their goals. We are proud to be a part of
                their success
              </Text>
              <Button
                style={{
                  backgroundColor: "white",
                  color: "#002741",
                  fontSize: 15,
                  height: 50,
                  padding: "0 20px",
                  marginTop: "2em",
                  width: "fit-content",
                }}
              >
                View All Stories
                <IoArrowForwardOutline
                  size={20}
                  style={{
                    marginLeft: "8px",
                  }}
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="landingServices ">
        <Text className="landingHeading">Services</Text>
        <Text type="p" className="landingTagline">
          We specialize in a targeted array of IT services, meticulously crafted
          to meet the demands of contemporary businesses. From translating your
          ideas into digital excellence to fostering growth, we offer a diverse
          range of solutions to propel your business forward.
        </Text>
        <Box className="services  animate__animated animate__fadeInUpBig">
          <Box className="serviceCardTop">
            <img src={blob} className="blobImage1" />
            <Box className="serviceCardTopContent">
              <Text type="h3" className="serviceCardTopContentHeading">
                Web Development
              </Text>
              <Text type="p" className="serviceCardTopContentDescription">
                Our web development services blend design and technology to
                create captivating websites. With responsive design and
                user-friendly interfaces, we ensure effective online presence,
                driving growth for your business.
              </Text>
            </Box>
          </Box>

          <Box className="serviceCardTop">
            <img src={blob2} className="blobImage2" />
            <Box className="serviceCardTopContent">
              <Text type="h3" className="serviceCardTopContentHeading">
                App Development
              </Text>
              <Text type="p" className="serviceCardTopContentDescription">
                Our app development services combine creativity and
                functionality to craft engaging mobile experiences. From concept
                to launch, we ensure seamless user interfaces and robust
                features, driving engagement and success for your app.
              </Text>
            </Box>
          </Box>

          <Box className="serviceCardTop">
            <img src={blob3} className="blobImage3" />
            <Box className="serviceCardTopContent">
              <Text type="h3" className="serviceCardTopContentHeading">
                DB Management
              </Text>
              <Text type="p" className="serviceCardTopContentDescription">
                Our Database Management services streamline data storage and
                retrieval, ensuring efficiency and security for your business
                operations. Empower your decision-making with organized and
                accessible data.{" "}
              </Text>
            </Box>
          </Box>

          <Box className="serviceCardTop" sx={{ width: "63%" }}>
            <img src={blob5} className="blobImage4" loading="lazy"/>
            <Box className="serviceCardTopContent">
              <Text type="h3" className="serviceCardTopContentHeading">
                Emerging Technologies
              </Text>
              <Text type="p" className="serviceCardTopContentDescription">
                Our Emerging Technologies services harness the latest
                innovations to propel your business forward. From AI and
                blockchain to IoT and beyond, we deliver cutting-edge solutions
                tailored to your needs. Stay ahead of the curve and unlock new
                opportunities with our expertise in emerging technologies.
              </Text>
            </Box>
          </Box>
          <Box className="serviceCardTop">
            <img src={blob4} className="blobImage5" />
            <Box className="serviceCardTopContent">
              <Text type="h6" className="serviceCardTopContentHeading">
                Unsure about your need?
              </Text>
              <Text type="p" className="serviceCardTopContentDescription">
                We offer a free consultation to help you identify the right
                services for your business. Book a consultation with us and let
                us guide you through the process.
              </Text>

              <Button
                color="secondary"
                style={{
                  marginTop: "1em",
                  borderRadius: 24,
                }}
                onClick={() => scrollToClass("col1BodyDetails")}
              >
                Book Free Consultation
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="knowMore">
        <Text className="knowMoreHeading">Team that makes it all possible</Text>

        <Text type="p" className="landingTagline">
          Our team of experts is dedicated to delivering high-quality services
          and solutions to help you grow your business. We are committed to
          providing you with the best possible experience and ensuring your
          success.
        </Text>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {[
            "Dedicated Project Managers",
            "Experienced Developers",
            "Creative Designers",
            "Quality Assurance Experts",
            "Customer Support Team",
          ].map((item, index) => (
            <Box key={index} className="knowMoreCard">
              <IoShieldCheckmarkSharp color="#2dd06b" />
              <Text type="h6" className="knowMoreCardHeading">
                {item}
              </Text>
            </Box>
          ))}
        </Box>
        <Button color="secondary" className="hireTeamButton">
          Hire Team
        </Button>
      </Box>
      <Box className="landingFaq">
        <Text className="landingHeading">FAQs</Text>

        <Text type="p" className="landingTagline">
          Your Trusted Partner in IT Consultation & Development Services. We
          help you to transform your ideas into digital excellence. We provide a
          wide range of services to help you grow your business.
        </Text>
        {FAQDATA.map((item: any, index: any) => (
          <FAQCard question={item.question} key={index} answer={item.answer} />
        ))}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            margin: "1.6em 0",
          }}
        >
          {/* <Box
            className="knowMoreCard"
            onClick={() => window.open("tel:+1(443)584-3062", "_blank ")}
            sx={{ cursor: "pointer" }}
          >
            <img src={usa} width={20} height={20} alt="usa" />
            <Text type="h6" className="knowMoreCardHeading">
              Call Us
            </Text>
          </Box> */}

          <Box
            className="knowMoreCard"
            onClick={() => window.open("tel:+918809660774", "_blank ")}
            sx={{ cursor: "pointer" }}
          >
            <img src={india} width={20} height={20} alt="india" />
            <Text type="h6" className="knowMoreCardHeading">
              Call Us
            </Text>
          </Box>

          <Box
            className="knowMoreCard"
            onClick={() => window.open("mailto:contact@insyt.in", "_blank ")}
            sx={{ cursor: "pointer" }}
          >
            <MdOutlineCallMade color="#2dd06b" size={20} />
            <Text type="h6" className="knowMoreCardHeading">
              Contact@Insyt.in
            </Text>
          </Box>
        </Box>
      </Box>

      <Footer
        onClickFreeConsultation={() => scrollToClass("col1BodyDetails")}
        onClickExploreServices={() => scrollToClass("landingServices")}
        onServicesClick={() => scrollToClass("landingServices")}
        onWhyUsClick={() => scrollToClass("landingWhy")}
        onFaqClick={() => scrollToClass("landingFaq")}
        onAboutClick={() => scrollToClass("knowMore")}
      />

      <Alert
        open={isAlert.type !== ""}
        handleClose={() => setisAlert({ type: "", message: "" })}
        type={isAlert.type}
        message={isAlert.message}
      />
    </Box>
  );
};

export default Index;
